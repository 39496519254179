/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from "./Footer.module.css"
import fb from "../../assets/images/footer/fb.png"
import twitter from "../../assets/images/footer/twitter.png"
import youtube from "../../assets/images/footer/youtube.png"


const Footer = (props) => {
    return (
        <>
            <footer className='bg-primary '>
                <div className='text-white text-center'>
                    MyZoo - Tout droits réservés
                </div>
                <div className='row no-gutters align-items-center text-center pt-2'>
                    <div className='col-3'>
                        <a href='www//facebook...' className='d-block' target='_blank' >
                            <img src={fb} alt='facebook'className='imgFb'></img>
                        </a>
                    </div>
                    <div className='col-3'>
                        <a href='www//facebook...' className='d-block' target='_blank' >
                            <img src={twitter} alt='twitter'className='imgTwitter'></img>
                        </a>
                    </div>
                    <div className='col-3'>
                        <a href='www//facebook...' className='d-block' target='_blank' >
                            <img src={youtube} alt='youtube'className='imgYoutube'></img>
                        </a>
                    </div>
                    <div className='col-3'>
                        < NavLink to="/mentionLegales" className={["nav-link", "p-0", "m-0", classes.p_footerLink].join(" ")}>
                            mentions légales
                        </NavLink>
                        <a href='mailto:contact@myzoo.com' className={["nav-link", "p-0", "m-0", classes.p_footerLink].join(" ")}>
                            contact@myzoo.com
                        </a>
                        <a href='https://www.exemple.dev.webkeypack.com/SERVEURANIMAUX/back/login' className={["nav-link", "p-0", "m-0", classes.p_footerLink].join(" ")}>
                            espace administrateur
                        </a> 
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;