import React from 'react';

const TitreH1 = (props) => {
    //la variable backgroundColor représente le fait de pouvoir changer la couleur du bcg si la props est renseignée
    let backgroundColor = props.bgColor ? props.bgColor : "bg-primary";
    let monCss = `border border-dark  p-2 texte-white text-center  ${backgroundColor}`;

    return (
        <h1 className={monCss}>{props.children}</h1>
    );
};

export default TitreH1;