import React, { Component } from 'react';
import TitreH1 from "../../../components/UI/titres/TitreH1"
import banderole from "../../../assets/images/banderole.png"
import logo from "../../../assets/images/logo.png" 

class Accueil extends Component {
    componentDidMount = () => {
        document.title = "parc d'animaux MyZoo"
    }
    render() {
        return (
            <div>
                <img src={banderole} alt="banderole" className='img-fluid'></img>
                <TitreH1 bgColor="bg-success">Venez visiter le parc d'animaux MyZoo !!!</TitreH1>
                <div className='container'>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quos a rem voluptate porro perferendis sit dignissimos? Eaque maiores soluta dolore voluptates, delectus, cum, blanditiis aliquid officia architecto totam ipsum?
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quos a rem voluptate porro perferendis sit dignissimos? Eaque maiores soluta dolore voluptates, delectus, cum, blanditiis aliquid officia architecto totam ipsum?
                    </p>
                    <div className='row no-gutters align-items-center'>
                        <div className='col-12 col-md-6'>
                            <img src={logo} alt='logo du site' className='img-fluid'></img>
                        </div>
                        <div className='col-12 col-md-6'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quos a rem voluptate porro perferendis sit dignissimos? Eaque maiores soluta dolore voluptates, delectus, cum, blanditiis aliquid officia architecto totam ipsum?
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quos a rem voluptate porro perferendis sit dignissimos? Eaque maiores soluta dolore voluptates, delectus, cum, blanditiis aliquid officia architecto totam ipsum?
                        </div>
                        <div className='col-12 col-md-6'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quos a rem voluptate porro perferendis sit dignissimos? Eaque maiores soluta dolore voluptates, delectus, cum, blanditiis aliquid officia architecto totam ipsum?
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quos a rem voluptate porro perferendis sit dignissimos? Eaque maiores soluta dolore voluptates, delectus, cum, blanditiis aliquid officia architecto totam ipsum?
                        </div>
                        <div className='col-12 col-md-6'>
                            <img src={logo} alt='logo du site' className='img-fluid'></img>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Accueil;