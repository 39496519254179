import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../../../assets/images/logo.png"

const NavBarre = (props) => (
    <>
        <nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="*">
                    <img src={logo} alt="logo myzoo" width="50px" className='rounded'/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarColor01">
                    <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                            <NavLink  className="nav-link" to="/">Accueil</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink  className="nav-link" to="/animaux">Les animaux</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/contact">Contact</NavLink>
                        </li>
                    </ul>
                
                </div>
            </div>
        </nav>
    </>
    );

export default NavBarre;