import React, { Component} from 'react';
import {Route, Routes} from "react-router-dom"
import NavBarre from "../../components/UI/navBarre/NavBarre"
import Accueil from "./accueil/Accueil"
import Error from '../../components/error/Error';
import Footer from '../../components/footer/Footer';
import Application from './application/Application';
import Contact from "./contact/Contact";

class Site extends Component {
    render() {
        return (
            <>
            <div className='site'>
                < NavBarre />
                <Routes>
                    <Route path='/' element={< Accueil />}/>
                    <Route path='/contact' element={ <Contact />}/>
                    <Route path='/animaux' element={< Application /> }/>

                    <Route path='*' element={< Error type="404">La page n'existe pas</ Error>}/>
                </Routes>
                <div className='minSite'></div>
            </div>
                
                < Footer />
            </>

        );
    }
}

export default Site;