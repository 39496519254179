import React from 'react';
import {withFormik} from "formik";
import * as Yup from "yup";

//installer formik*********npm install --save formik yup

const Formulaire = (props) => {
    return (
        <>
        <form id='formulaire'>
            <div className="form-group">
                <label htmlFor="nom" className="form-label">Nom : </label>
                <input type="text" className="form-control" id="nom" aria-describedby="nomHelp"
                    name="nom"
                    onChange={props.handleChange}
                    value={props.values.nom}
                    onBlur = {props.handleBlur}
                />
                {
                    props.touched.nom && props.errors.nom && <span style={{color:"red"}}>{props.errors.nom}</span>
                }
            </div>
            <div className="form-group">
                <label htmlFor="email" className="form-label">Email : </label>
                <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                    name="email"
                    onChange={props.handleChange}
                    value={props.values.email}
                    onBlur = {props.handleBlur}
                />
                {
                    props.touched.email && props.errors.email && <span style={{color:"red"}}>{props.errors.email}</span>
                }
            </div>
            <div className="form-group">
                <label htmlFor="message" className="form-label">Message : </label>
                <textarea className="form-control" id="message" rows="3"
                    name="message"
                    onChange={props.handleChange}
                    value={props.values.message}
                    onBlur = {props.handleBlur}
                />
                {
                    props.touched.message && props.errors.message && <span style={{color:"red"}}>{props.errors.message}</span>
                }
            </div>
            <button type="submit" className="btn btn-primary"onClick={props.handleSubmit}>Envoyer</button>
        </form>
        </>
    );
};

export default withFormik({
    mapPropsToValues : () => ({
        nom:"",
        email:"",
        message:""
    }),
    validationSchema : Yup.object().shape({
        nom: Yup.string()
            .min(5,"Le nom doit avoir plus de 5 caractères")
            .required("Le nom est obligatoire"),
        email: Yup.string()
            .email("L'email n'a pas le bon format")
            .required("L'email est obligatoire"),
        message: Yup.string()
            .min(10,"Le message doit faire plus de 10 caractères")
            .max(200,"Le message doit faire moins de 200 caractères")
    }),
    handleSubmit: (values,{props}) => {
        const message = {
            nom : values.nom,
            email : values.email,
            contenu : values.message
        }
        props.sendMail(message);
        document.getElementById("formulaire").reset()

    }
})(Formulaire);

//la liaison entre le formulaire et le serveur au submit, se fait avec handleSubmit et sendMail dans ce fichier
//il faut aussi faire le lien sur le fichier contact.js, pour récupérer les données du formulaire avec la fonction handleEnvoiMail
//le lien au niveau du back, se fait sur le fichier index.php, dans les routes
