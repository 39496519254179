import React from "react";
import { BrowserRouter } from "react-router-dom";
import './App.css';
import Site from "./containers/site/Site";

function App() {

    return (
      <>
      {/* pour mettre en ligne et accéder à la BDD, il faut à côté de BrowserRouter mettre basename="nom du dossier" */}
      {/* sauf si le dossier est à la racine, du coup on ne met rien */}
      <BrowserRouter>
        < Site />
      </BrowserRouter>
      </>
    );
}

export default App;
