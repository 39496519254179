import React, { Component } from 'react';
import Titre from "../../../components/UI/titres/TitreH1"
import Formulaire from './formulaire/Formulaire';
import axios from "axios";

class Contact extends Component {
    componentDidMount = () => {
        document.title = "Page de contact";
    }
    handleEnvoiMail = (message) => {
        axios.post("https://exemple.dev.webkeypack.com/SERVEURANIMAUX/front/sendMessage",message)
        
            .then(reponse=> {
                console.log(reponse)
                alert("votre message a bien été envoyé");
                
            })
            .catch(error => {
                console.log(error)
                alert("Une erreur est survenue lors de l'envoi de votre email");

            })
    }
    render() {
        return (
            <div>
                <Titre bgColor="bg-success"> Contactez nous !</Titre>
                <div className='container'>
                    <h2>Adresse</h2>
                    xxxxxxxxxxxxxxxxxx
                    <h2>Téléphone</h2>
                    00 00 00 00 00
                    <h2>Vous préférez nous écrire ?</h2>
                    <Formulaire sendMail = {this.handleEnvoiMail}/>
                </div>
            </div>
        );
    }
}

export default Contact;